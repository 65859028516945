<template>
  <PageLayout>
    <template #header>
      <h1>{{ $t('contactsSet.title') }}</h1>
    </template>
    <div class="d-flex justify-content-between mb-4">
      <h3>
        {{ $t('contactsSet.subTitle') }}
      </h3>
      <Button v-if="isAdmin" class="newContactSet" @click="showContactSetCreate = true">{{
        $t('contactsSet.actions.newContact')
      }}</Button>
    </div>
    <div class="filters sticky-top bg-white">
      <SearchBar
        :search-value="contactSetFilters.text"
        :placeholder="$t('contactsSet.actions.searchPlaceholder')"
        @onClear="() => (contactSetFilters.text = '')"
        @onChange="(value) => (contactSetFilters.text = value)"
      />

      <SearchBusinessFilter
        :title="$t('contactsSet.actions.worksAt')"
        :selected="contactSetFilters.worksAt"
        :clearable="true"
        @on-select="(business) => (contactSetFilters.worksAt = business.id)"
        @on-clear="() => (contactSetFilters.worksAt = '')"
      ></SearchBusinessFilter>

      <SearchBusinessFilter
        :title="$t('contactsSet.actions.worksWith')"
        :selected="contactSetFilters.worksWith"
        :clearable="true"
        @on-select="(business) => (contactSetFilters.worksWith = business.id)"
        @on-clear="() => (contactSetFilters.worksWith = '')"
      ></SearchBusinessFilter>
    </div>

    <Table
      v-loading="loading"
      :columns="columns"
      :show-index="(currentPage - 1) * pagination.limit + 1"
      :data="tableData"
      :cell-class="(rowIndex) => activeRowActionsIndex === rowIndex && 'bg-secondary'"
      border
      rounded
      @row-click="handleTableRowClick"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + PAGE_LIMIT * (currentPage - 1) }}
      </template>

      <template #cell-phones="{ rowData: { phones } }">
        <ListOnHover :items="phones.map(({ number }) => number)" class="w-100 px-2"> </ListOnHover>
      </template>

      <template #cell-role="{ rowData: { role } }">
        {{ role.length === 1 ? role[0] : '-' }}
      </template>

      <template #cell-actions="{ rowData, rowIndex }">
        <el-dropdown
          class="d-flex justify-content-center"
          trigger="click"
          placement="bottom-start"
          @command="handleAction($event, rowData)"
          @visible-change="(isVisible) => (activeRowActionsIndex = isVisible ? rowIndex : -1)"
        >
          <Button
            :id="`actions-row-${rowIndex}`"
            type="text"
            class="p-1 actions-btn text-typography-primary"
            :class="{ active: activeRowActionsIndex === rowIndex }"
            @click.stop
          >
            <KebabIcon />
          </Button>

          <el-dropdown-menu>
            <div class="d-flex flex-column">
              <el-dropdown-item :command="ACTIONS.CONTACTSET_EDIT">
                <div
                  class="w-100 d-flex flex-row justify-content-center align-items-center gap-2 text-typography-primary"
                >
                  <EditIcon />
                  <p>
                    {{ $t('contactsSet.table.actions.edit') }}
                  </p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item :command="ACTIONS.CONTACTSET_DELETE">
                <div
                  class="w-100 d-flex flex-row justify-content-center align-items-center gap-2 text-typography-primary"
                >
                  <TrashCanIcon />
                  <p>
                    {{ $t('contactsSet.table.actions.delete') }}
                  </p>
                </div>
              </el-dropdown-item>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>

    <div class="d-flex justify-content-end my-4">
      <el-pagination
        small
        layout="prev, pager, next, jumper"
        background
        :current-page.sync="currentPage"
        :page-size="pagination.limit"
        :page-count="Math.ceil(totalCount / pagination.limit)"
        class-="float-right"
      />
    </div>

    <ContactSetEditModal
      v-if="showContactSetCreate || !!contactSetForEdit"
      :is-edit="!!contactSetForEdit"
      :contact="contactSetForEdit"
      :clarity-business-id="clarityBusiness.id"
      @close="onCloseContactSetModal"
    ></ContactSetEditModal>

    <ContactSetModal
      v-if="!!contactSetDetails"
      :clarity-business-id="clarityBusiness.id"
      :contact="contactSetDetails"
      @close="() => (contactSetDetails = null)"
    ></ContactSetModal>
  </PageLayout>
</template>

<script>
import { computed, ref, watch, reactive } from 'vue';
import { isNil, reject, either, isEmpty } from 'ramda';
import { debounce } from 'lodash';

import { PageLayout, Table, Button, SearchBar } from '@/modules/core';
import { useUser } from '@/modules/auth';
import { KebabIcon, EditIcon, TrashCanIcon } from '@/assets/icons';
import { useBusinesses } from '@/modules/businesses/compositions/businesses';

import { useContactSet, useContactSetDelete, useContactSets } from './composition/contactSet';
import { SearchBusinessFilter, ListOnHover } from './components';
import ContactSetEditModal from './contactSetEditModal.vue';
import ContactSetModal from './contactSetModal.vue';

const PAGE_LIMIT = 30;
const SEARCH_DEBOUNCE_TIME = 500;

const CONTACTSET_TABLE_HEADERS = {
  FIRSTNAME: 'firstName',
  LASTNAME: 'lastName',
  WORKSAT: 'worksAt',
  ROLE: 'role',
  GENDER: 'gender',
  EMAILS: 'emails',
  PHONES: 'phones',
  ACTIONS: 'actions',
};

const ACTIONS = {
  CONTACTSET_EDIT: 'openChat',
  CONTACTSET_DELETE: 'makePayment',
};

export default {
  name: 'ContactSetsPage',
  components: {
    PageLayout,
    Table,
    Button,
    KebabIcon,
    EditIcon,
    TrashCanIcon,
    SearchBar,
    SearchBusinessFilter,
    ListOnHover,
    ContactSetEditModal,
    ContactSetModal,
  },
  setup() {
    const currentPage = ref(1);
    const contactSetFilters = reactive({
      text: '',
      debouncedText: '',
      worksAt: '',
      worksWith: '',
    });

    const pagination = computed(() => ({
      limit: PAGE_LIMIT,
      offset: (currentPage.value - 1) * PAGE_LIMIT,
    }));

    const { isAdmin, loading: userLoading } = useUser();
    const { deleteContactSet } = useContactSetDelete();
    const { contactSet: contactSetForEdit, fetchContactSet: fetchEditContactSet } = useContactSet();
    const { contactSet: contactSetDetails, fetchContactSet: fetchContactSetDetails } = useContactSet();

    const {
      contactSets,
      totalCount,
      loading: contactSetsLoading,
      refetch: contactSetsRefetch,
    } = useContactSets(
      computed(() =>
        reject(either(isNil, isEmpty), {
          searchText: contactSetFilters.debouncedText,
          worksAt: contactSetFilters.worksAt,
          businessId: contactSetFilters.worksWith,
          pagination: pagination.value,
        })
      )
    );

    watch(
      () => contactSetFilters.text,
      debounce((newVal) => (contactSetFilters.debouncedText = newVal), SEARCH_DEBOUNCE_TIME)
    );

    const { businesses } = useBusinesses(
      computed(() => ({
        first: 1,
        after: 0,
        capabilities: {
          serviceProvider: true,
        },
      }))
    );

    const clarityBusiness = computed(() => businesses.value.businesses.nodes[0]);

    return {
      activeRowActionsIndex: ref(-1),
      contactSets,
      totalCount,
      loading: computed(() => userLoading.value || contactSetsLoading.value),
      currentPage,
      pagination,
      contactSetFilters,
      isAdmin,
      contactSetsRefetch,
      deleteContactSet,
      fetchEditContactSet,
      contactSetForEdit,
      contactSetDetails,
      fetchContactSetDetails,
      clarityBusiness,
    };
  },
  data() {
    return {
      ACTIONS,
      PAGE_LIMIT,
      showContactSetCreate: false,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('contactsSet.table.firstname'),
          key: CONTACTSET_TABLE_HEADERS.FIRSTNAME,
        },
        {
          header: this.$t('contactsSet.table.lastname'),
          key: CONTACTSET_TABLE_HEADERS.LASTNAME,
        },
        {
          header: this.$t('contactsSet.table.worksAt'),
          key: CONTACTSET_TABLE_HEADERS.WORKSAT,
        },
        {
          header: this.$t('contactsSet.table.role'),
          key: CONTACTSET_TABLE_HEADERS.ROLE,
        },
        {
          header: this.$t('contactsSet.table.gender'),
          key: CONTACTSET_TABLE_HEADERS.GENDER,
        },
        {
          header: this.$t('contactsSet.table.email'),
          key: CONTACTSET_TABLE_HEADERS.EMAILS,
        },
        {
          header: this.$t('contactsSet.table.phone'),
          key: CONTACTSET_TABLE_HEADERS.PHONES,
        },
        {
          header: '',
          key: CONTACTSET_TABLE_HEADERS.ACTIONS,
          width: '40px',
          customClass: 'p-0',
        },
      ];
    },
    tableData() {
      const worksAt = (contactSet) => {
        if (contactSet.worksAt.length > 1) {
          return this.$t('contactsSet.table.rows.worksAtMultiple', { count: contactSet.worksAt.length });
        }

        return contactSet.worksAt.length ? contactSet.worksAt[0].name : null;
      };

      const emails = (contactSet) => contactSet.emails?.[0].email;
      const roles = (contactSet) =>
        reject(
          isNil,
          contactSet.worksAt?.map((workplace) => workplace?.role)
        );

      return this.contactSets.map((contactSet) => ({
        id: contactSet.id,
        [CONTACTSET_TABLE_HEADERS.FIRSTNAME]: contactSet.firstName || contactSet.officeName,
        [CONTACTSET_TABLE_HEADERS.LASTNAME]: contactSet.lastName,
        [CONTACTSET_TABLE_HEADERS.WORKSAT]: worksAt(contactSet),
        [CONTACTSET_TABLE_HEADERS.ROLE]: roles(contactSet),
        [CONTACTSET_TABLE_HEADERS.GENDER]:
          contactSet.gender && this.$t(`contactsSet.table.rows.gender.${contactSet.gender}`),
        [CONTACTSET_TABLE_HEADERS.EMAILS]: emails(contactSet),
        [CONTACTSET_TABLE_HEADERS.PHONES]: contactSet.phones || [],
      }));
    },
  },
  methods: {
    async handleTableRowClick(index) {
      await this.fetchContactSetDetails({ id: this.contactSets[index].id });
    },
    onCloseContactSetModal() {
      this.showContactSetCreate = false;
      this.contactSetForEdit = undefined;
    },
    async handleAction(command, rowData) {
      switch (command) {
        case ACTIONS.CONTACTSET_EDIT: {
          await this.fetchEditContactSet({ id: rowData.id });
          break;
        }

        case ACTIONS.CONTACTSET_DELETE: {
          this.$confirm(
            this.$t('contactsSet.confirmation.delete.body'),
            this.$t('contactsSet.confirmation.delete.title'),
            {
              confirmButtonText: this.$t('contactsSet.table.actions.delete'),
              confirmButtonClass: 'el-button--danger',
              cancelButtonText: this.$t('cancel'),
              cancelButtonClass: 'el-button--secondary',
            }
          )
            .then(async (confirm) => {
              if (confirm) {
                await this.deleteContactSet({ id: rowData.id }, { refetchQueries: ['contactSets'] });
              }
            })
            .catch(() => null);
          break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.top-section {
  top: $page-layout-dashboard-hight;
  padding: 1rem 0;
}

.newContactSet {
  display: flex;
  height: 32px;
  &:before {
    content: '+';
    margin-inline-end: 5px;
    line-height: 14px;
    font-size: 20px;
    font-weight: 400;
  }
}

.search-icon {
  margin-top: 12px;
  width: 1rem;
  height: 1rem;
  margin-right: 4px;
}

.filters {
  top: 8rem;
  padding: 1rem 0;
}

.filters > div {
  margin-inline-end: 12px;
}

.actions-btn {
  &:hover {
    background: $secondary;
  }
  &.active {
    background-color: $gray-outline;
    visibility: visible;
  }
}

tr {
  .actions-btn {
    visibility: hidden;
  }

  &:hover .actions-btn {
    visibility: visible;
  }
}

:deep(input.el-input__inner) {
  height: 32px;
}

:deep(.el-input__prefix .search-icon) {
  margin-top: 8px;
}

:deep(i.el-icon-circle-close.el-input__clear) {
  margin-top: -2px;
  width: 1rem;
  height: 1rem;
}
</style>
