<template>
  <div>
    <el-dialog
      :show-close="false"
      width="800px"
      height="600px"
      visible
      custom-class="contactSet-modal"
      @close="$emit('close')"
    >
      <template #title>
        <div class="d-flex justify-content-between">
          <div>
            {{ $t('contactsSetDetails.title') }}
          </div>
          <div class="d-flex gap-2">
            <el-dropdown
              v-if="showActions"
              class="d-flex justify-content-center"
              trigger="click"
              placement="bottom-start"
              @command="handleAction"
            >
              <Button type="icon" class="p-0">
                <KebabIcon />
              </Button>

              <el-dropdown-menu>
                <div class="d-flex flex-column">
                  <el-dropdown-item :command="ACTIONS.CONTACTSET_EDIT">
                    <div
                      class="w-100 d-flex flex-row justify-content-center align-items-center gap-2 text-typography-primary"
                    >
                      <EditIcon />
                      <p>
                        {{ $t('contactsSetDetails.actions.edit') }}
                      </p>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item :command="ACTIONS.CONTACTSET_REMOVE">
                    <div
                      class="w-100 d-flex flex-row justify-content-center align-items-center gap-2 text-typography-primary"
                    >
                      <TrashCanIcon />
                      <p>
                        {{ $t('contactsSetDetails.actions.delete') }}
                      </p>
                    </div>
                  </el-dropdown-item>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <Button type="icon" class="p-0" @click="$emit('close')">
              <CloseIcon />
            </Button>
          </div>
        </div>
      </template>

      <el-divider />

      <div class="sub-header d-flex align-items-center">
        <div
          class="bg-primary rounded-pill d-flex justify-content-center align-items-center"
          style="height: 36px; width: 36px"
        >
          <small class="bold-header text-uppercase text-white">{{ contactInitials }}</small>
        </div>
        <div class="contact-name">
          <div class="bold-header">
            {{
              contact.type === CONTACT_TYPE.CONTACT
                ? `${contact.firstName} ${contact?.lastName || ''}`
                : contact.officeName
            }}
          </div>
          <div>
            {{ contact.gender && $t(`contactsSetModal.details.genderSelect.${contact.gender}`) }}
          </div>
        </div>
      </div>

      <div class="details d-flex gap-6">
        <div class="section d-flex flex-column gap-4">
          <div class="d-flex flex-column gap-3">
            <div class="section-title">
              {{ $t('contactsSetModal.details.title') }}
            </div>
            <div
              v-for="(phone, index) in contact.phones"
              :key="`${phone.number}-${index}`"
              class="channel d-flex gap-6"
            >
              <div class="field">{{ $t('contactsSetModal.details.phone', { count: index + 1 }) }}</div>
              <div class="value" style="direction: ltr">{{ phone.number }}</div>
            </div>
            <div v-for="(email, index) in contact.emails" :key="`${email}-${index}`" class="channel d-flex gap-6">
              <div class="field">{{ $t('contactsSetModal.details.email', { count: index + 1 }) }}</div>
              <div class="value">{{ email.email }}</div>
            </div>
          </div>
          <div v-if="contact.note" class="d-flex flex-column gap-3">
            <div class="section-title">
              {{ $t('contactsSetModal.details.note') }}
            </div>
            <div>
              {{ contact.note }}
            </div>
          </div>

          <div v-if="contact.worksAt?.length" class="d-flex flex-column gap-4">
            <el-divider />
            <div class="section-title">
              {{ $t('contactsSetModal.workplace.title') }}
            </div>
            <div
              v-for="(worksAt, index) in contact.worksAt"
              :key="worksAt.id"
              class="works-at d-flex flex-column gap-3"
              :class="{ active: worksAt.id === selectedWorksPlace.id }"
              @click="() => (selectedWorksPlace = worksAt)"
            >
              <div class="d-flex gap-6">
                <div class="works-at-title">
                  {{ $t('contactsSetModal.workplace.workplaceCount', { count: index + 1 }) }}
                </div>
                <div class="works-at-name">{{ worksAt.name }}</div>
              </div>
              <div class="d-flex gap-6">
                <div class="works-at-title">{{ $t('contactsSetModal.workplace.role') }}</div>
                <div>{{ worksAt.role }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="section d-flex flex-column">
          <div class="section-title pb-1">
            {{
              selectedWorksPlace
                ? $t('contactsSetModal.worksWith.title', {
                    worksAt: selectedWorksPlace.name,
                  })
                : ''
            }}
          </div>

          <div class="body d-flex flex-column">
            <SearchBar
              class="mb-0 search-business-select w-100"
              :search-value="workWithFilter"
              :placeholder="$t('contactsSet.actions.searchPlaceholder')"
              @onClear="() => (workWithFilter = '')"
              @onChange="(value) => (workWithFilter = value)"
            />
            <div v-for="business in worksWithItems" :key="business.id">
              <div class="work-with-item">
                {{ business.name }}
              </div>

              <el-divider />
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <ContactSetEditModal
      v-if="showContactSetEdit"
      :is-edit="true"
      :clarity-business-id="clarityBusinessId"
      :contact="contact"
      @close="onCloseContactSetModal"
    ></ContactSetEditModal>
  </div>
</template>

<script>
import { Button, SearchBar } from '@/modules/core';
import { KebabIcon, TrashCanIcon, CloseIcon, EditIcon } from '@/assets/icons';

import { useContactSetDelete } from './composition/contactSet';
import ContactSetEditModal from './contactSetEditModal.vue';
import { CONTACT_TYPE } from './components/contactSetInfo.vue';

const ACTIONS = {
  CONTACTSET_REMOVE: 'remove',
  CONTACTSET_EDIT: 'edit',
};

export default {
  name: 'ContactSetModal',
  components: {
    Button,
    KebabIcon,
    TrashCanIcon,
    CloseIcon,
    EditIcon,
    ContactSetEditModal,
    SearchBar,
  },
  props: {
    showActions: { type: Boolean, required: false, default: true },
    contact: { type: Object, required: true },
    clarityBusinessId: { type: String, required: true },
  },
  setup() {
    const { deleteContactSet } = useContactSetDelete();

    return { deleteContactSet };
  },
  data() {
    return {
      ACTIONS,
      CONTACT_TYPE,
      showContactSetEdit: false,
      selectedWorksPlace: this.contact.worksAt?.[0],
      workWithFilter: '',
    };
  },
  computed: {
    worksWithItems() {
      return this.selectedWorksPlace?.worksWith
        ?.filter(({ id }) => id !== this.clarityBusinessId)
        ?.filter(({ name }) => name.toLowerCase().includes(this.workWithFilter.toLowerCase()));
    },
    contactInitials() {
      const first = this.contact?.firstName?.[0] || this.contact.officeName?.[0];
      const second = this.contact.lastName?.[0];

      return second ? `${first} ${second}` : `${first}`;
    },
  },
  methods: {
    onCloseContactSetModal() {
      this.showContactSetEdit = false;
      this.$emit('close');
    },
    async handleAction(command) {
      switch (command) {
        case ACTIONS.CONTACTSET_REMOVE:
          await this.onContactSetDelete();
          break;

        case ACTIONS.CONTACTSET_EDIT:
          this.showContactSetEdit = true;
          break;
      }
    },
    async onContactSetDelete() {
      this.$confirm(this.$t('contactsSet.confirmation.delete.body'), this.$t('contactsSet.confirmation.delete.title'), {
        confirmButtonText: this.$t('contactsSet.table.actions.delete'),
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: this.$t('cancel'),
        cancelButtonClass: 'el-button--secondary',
      })
        .then(async (confirm) => {
          if (confirm) {
            await this.deleteContactSet({ id: this.contact.id }, { refetchQueries: ['contactSets'] });
            this.$emit('close');
          }
        })
        .catch(() => null);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.sub-header {
  padding: 24px 0px;
  .bold-header {
    font-size: 16px;
    font-weight: 700;
  }
  .contact-name {
    padding: 0px 10px;
  }
}

.el-divider {
  margin: 4px 0px;
}

.section {
  flex: 1;

  .section-title {
    font-weight: 550;
    line-height: 20px;
    word-break: normal;
  }

  .channel {
    & > .field {
      text-align: right;
      color: $typography-secondary;
      line-height: 20px;
    }
    & > .value {
      color: $typography-primary;
      line-height: 20px;
    }
  }

  .works-at {
    border-radius: 4px;
    border: 1px solid $outline;
    padding: 16px;
    cursor: pointer;
    color: $typography-secondary;

    &.active {
      border-radius: 4px;
      border: 1px solid #9295a5;
    }

    .works-at-title {
      min-width: 80px;
    }

    .works-at-name {
      color: $typography-primary;
      font-weight: 500;
      line-height: 20px;
      word-break: normal;
    }
  }

  .body {
    flex: 1;
    border: 1px solid $gray-outline;
    border-radius: 4px;

    .work-with-item {
      height: 38px;
      padding: 12px;
      line-height: 100%;
    }

    .work-with-item-remove {
      margin: 0px 12px;
      color: #71767d;
    }

    .search-business {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 31px;
      padding: 0px 8px;
      background-color: $light-gray;
      border-bottom: 1px solid $gray-outline;
    }

    .el-divider {
      margin: 0px;
    }
  }
}

:deep(.contactSet-modal .el-dialog__header) {
  padding: 16px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;

  .el-dialog__title {
    font-size: 24px;
    line-height: 28px;
  }

  button {
    width: 28px;
    height: 28px;
    &:hover {
      background-color: $secondary;
      border-radius: 4px;
    }

    i {
      font-size: 20px;
      color: $black;
    }
  }
}

:deep(.contactSet-modal .el-dialog__body) {
  padding-top: 0px;
}

:deep(.search-business-select) {
  width: calc(100% + 2px) !important;
  margin-bottom: 0px !important;
  margin: -1px;

  & > input {
    border-end-end-radius: 0px;
    border-end-start-radius: 0px;
  }
}
</style>
